/*------------------------------------
  Highlight Color
------------------------------------*/
::-moz-selection {
  color: #fff;
  background-color: #377dff;
}

::selection {
  color: #fff;
  background-color: #377dff;
}

.bg-primary ::-moz-selection {
  color: #377dff;
  background-color: #fff;
}

.bg-primary ::selection {
  color: #377dff;
  background-color: #fff;
}

.mt-offset-5 {
  margin-top: -2rem;
}

/*------------------------------------
  Air Button Styles
------------------------------------*/
/* primary air */
.u-btn-primary--air {
  color: #377dff;
  background: rgba(55, 125, 255, 0.1);
  border-color: transparent;
}

.u-btn-primary--air:hover, .u-btn-primary--air:focus, .u-btn-primary--air:active, .u-btn-primary--air.active {
  color: #fff;
  background: #377dff;
  box-shadow: 0 4px 17px rgba(55, 125, 255, 0.35);
}


/*------------------------------------
  Clients
------------------------------------*/
.u-clients {
  width: 6rem;
  margin-left: auto;
  margin-right: auto;
}

.u-icon {
  position: relative;
  z-index: 1;
  line-height: 0.7;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 2.125rem;
  height: 2.125rem;
  font-size: 0.9375rem;
  border-radius: 0.25rem;
  transition: 0.3s ease-in-out;
}

/*------------------------------------
  Icon Air Styles
------------------------------------*/
/* primary air */
.u-icon-primary--air {
  color: #377dff;
  background: rgba(55, 125, 255, 0.1);
}

.u-icon-primary--air[href]:hover, .u-icon-primary--air[href]:focus, .u-icon-primary--air[href]:active {
  color: #fff;
  background: #377dff;
}

/* Extra Small */
.u-icon--xs {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.625rem;
  border-radius: 0.25rem;
}

/*------------------------------------
  Mockup Browser v1
------------------------------------*/
.u-browser-v1 {
  position: relative;
  max-width: 53.125rem;
}

.u-browser-v1__svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  max-width: 85%;
  border-radius: 0.5rem;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
}


/*------------------------------------
  Pricing v1
------------------------------------*/
.u-pricing-v1 {
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.125);
}

.u-pricing-v1-toggler:hover {
  color: #377dff;
  background-color: transparent;
  border-color: #377dff;
}

.u-pricing-v1-toggler:not(:disabled):not(.disabled):active, .u-pricing-v1-toggler:not(:disabled):not(.disabled).active, .u-pricing-v1-toggler:not(:disabled):not(.disabled):active, .u-pricing-v1-toggler:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #377dff;
  border-color: #377dff;
}

.u-pricing-v1-toggler-pos.u-badge-pos {
  position: absolute;
}

.u-pricing-v1__header {
  position: relative;
  color: #fff;
  background-color: #377dff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 2rem 2rem 5rem;
}

.u-pricing-v1__content {
  background-color: #fff;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  padding: 0 2rem 2rem;
}

.u-pricing-v1__content .disabled .u-icon {
  color: #77838f;
  background-color: rgba(119, 131, 143, 0.1);
}

.u-pricing-v1__list-item {
  font-size: 0.875rem;
}

.u-pricing-v1 .disabled {
  color: #77838f;
}


/*------------------------------------
  Fill Colors
------------------------------------*/
.u-fill-none {
  fill: none;
}

.u-fill-white {
  fill: #fff;
}

.u-fill-dark {
  fill: #1e2022;
}

.u-fill-primary {
  fill: #377dff;
}

.u-fill-success {
  fill: #00c9a7;
}

.u-fill-danger {
  fill: #de4437;
}

.u-fill-warning {
  fill: #ffc107;
}

.transition-3d-hover {
  transition: all 0.2s ease-in-out;
}

.transition-3d-hover:hover, .transition-3d-hover:focus {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}
