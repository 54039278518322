.gradient-primary {
  background-image: linear-gradient(0deg, rgba(55, 125, 255, 0.1) 0%, transparent 100%);
  background-repeat: repeat-x;
}

.partial-letsgetyourtstarted {
  .join-label {
    display: inline-block;
    font-weight: 500;
    line-height: 1.3;
    border-radius: 6.1875rem;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    color: #00c9a7;
    background-color: rgba(0, 201, 167, 0.1);
  }

  .button-pricing {
    color: #377dff;
    background: rgba(55, 125, 255, 0.1);
    border-color: transparent;
    min-width: 9.6875rem;
    border-radius: 6.1875rem;
  }

  .button-register {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    min-width: 9.6875rem;
    border-radius: 6.1875rem;
  }
}