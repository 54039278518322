//@import "~bootstrap/scss/bootstrap";
//@import "~mdb-ui-kit/css/mdb.min.css";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons";
@import "./styles.scss";
@import "./partial/pagenav";
@import "./partial/letsGetYouStarted";
@import "./layout/blogArticleLayout";

.pricing-page {
  .price-amount {
    font-size: 40px;
  }
}

.page-download {
  .download-card {
    &:hover {
      text-decoration: none;
    }
    text-align: center;
    img {
      width: 100px;
    }
  }
}

.page-contact-us {
  .header-image {
    position: absolute;
    width: 100%;
    height: 430px;
    background-position: center center;
    background-image: url(/dist/assets/img/1920x800/img13.jpg);
  }
  .contacts-content {
    margin-top: 350px;
    margin-bottom: 20;
  }
}


h1, h2 {
  color: #1266f1;
  font-weight: bold;
}
h3 {
  font-weight: bold;
}

/*-------------HASHIM-------------*/

.btn-hash-green {
  background-color: #1BB69D;
  color: #ffffff;
  &:hover {
    background-color: #188d74;
    color: #ffffff;
  }
}
.text-hash-green {
  color: #1BB69D;
}
.bg-hash-green {
  background-color: #1BB69D;
}

.btn-hash-green-light {
  background-color: #EFFAF8;
  &:hover {
    background-color: #188d74;
  }
}
.text-hash-green-light {
  color: #EFFAF8;
}
.bg-hash-green-light {
  background-color: #EFFAF8;
}

.btn-hash-red {
  background-color: #EE4A63;
  color: #ffffff;
  &:hover {
    background-color: #bd3d50;
    color: #ffffff;
  }
}
.text-hash-red {
  color: #EE4A63;
}
.bg-hash-red {
  background-color: #EE4A63;
}

.btn-hash-navy {
  background-color: #CCEAFE;
  //color: #ffffff;
  &:hover {
    background-color: #92c3e3;
    //color: #ffffff;
  }
}
.text-hash-navy {
  color: #CCEAFE;
}
.bg-hash-navy {
  background-color: #CCEAFE;
}

.btn-hash-red-light {
  background-color: #FDEDEF;
  //color: #ffffff;
  &:hover {
    background-color: #e7b7bd;
    //color: #ffffff;
  }
}
.text-hash-red-light {
  color: #FDEDEF;
}
.bg-hash-red-light {
  background-color: #FDEDEF;
}

.btn-hash-purple-light {
  background-color: #F2F4FD;
  //color: #ffffff;
  &:hover {
    background-color: #bfc4e0;
    //color: #ffffff;
  }
}
.text-hash-purple-light {
  color: #F2F4FD;
}
.bg-hash-purple-light {
  background-color: #F2F4FD;
}

.btn-hash-orange-light {
  background-color: #FFF7EF;
  //color: #ffffff;
  &:hover {
    background-color: #eadaca;
    //color: #ffffff;
  }
}
.text-hash-orange-light {
  color: #FFF7EF;
}
.bg-hash-orange-light {
  background-color: #FFF7EF;
}
